import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageServiceService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    addService(data:any){
        return this.http.post(this.url + 'service/add',data);
    }

    serviceList(data:any){
        return this.http.get(this.url + `service/list?page=${data?.page}&limit=${data?.pageLimit}`);
    }

    updateService(id:any,data:any){
        return this.http.put(this.url + 'service/update/'+id,data);
    }


    deleteService(id:any){
        return this.http.delete(this.url + 'service/delete/'+id);
    }


}
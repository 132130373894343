import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageCategoryService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    addCategory(data:any){
        return this.http.post(this.url + 'category/add',data);
    }

    serviceList(){
        return this.http.get(this.url + `service/list?page=1&limit=1000`);
    }

    categoryList(data:any){
        return this.http.post(this.url + `category/list`,data);
    }

    updateCategory(id:any,data:any){
        return this.http.put(this.url + 'category/update/'+id,data);
    }


    deleteCategory(id:any){
        return this.http.delete(this.url + 'category/delete/'+id);
    }


}
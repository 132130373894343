<div class="header">
    <div class="left-sec">
        <ng-container *ngIf="welcomeUserMsg">
            <h1>Dashboard</h1>
        </ng-container>
        <h1 *ngIf="dashboardHeading">Dashboard</h1>
        <h1 *ngIf="changePasswordHeading">Change Password</h1>
        <h1 *ngIf="manageCategoriesHeading">Manage Categories</h1>
        <h1 *ngIf="manageSubCategoriesHeading">Manage Sub Categories</h1>
        <h1 *ngIf="faqsHeading">FAQ</h1>
        <h1 *ngIf="subAdminHeading">Manage Sub Admin</h1>
        <h1 *ngIf="privacyPolicyHeading">Privacy Policy</h1>
        <h1 *ngIf="termsconditionsHeading">Terms & Conditions</h1>
        <h1 *ngIf="manageServiceHeading">Manage Services</h1>
        <h1 *ngIf="buyLeadsHeading">QuickBuy Leads</h1>
        <h1 *ngIf="tierdPlanHeading">Real Estate Leads</h1>
        <h1 *ngIf="serviceRequestHeading">Service Providers Inquiries</h1>
        <h1 *ngIf="globalQueryHeading">Contact Us</h1>

    </div>


    <div class="right-sec">
        <div class="btn-group" dropdown placement="bottom right">
            <button id="button-alignment" dropdownToggle type="button" class="btn dropdown-toggle"
                aria-controls="dropdown-alignment"><mat-icon>settings</mat-icon> Settings
            </button>
            <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-alignment">
                <li style="cursor: pointer;" role="menuitem"><a class="dropdown-item admin-dropdown"
                        (click)="openViewProfileModal()">View Profile</a></li>
                <li role="menuitem"><a class="dropdown-item admin-dropdown" routerLink="/edit-profile">Change
                        Password</a></li>
                <li role="menuitem"><a class="dropdown-item admin-dropdown"
                        (click)="openLogoutModal()">Logout</a></li>
            </ul>
        </div>

    </div>
</div>

<ng-template #template1>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide(); reset()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body delete-modal">
        <h3>Update Profile</h3>
        <div class="partner-form custom-form-field-container">
            <form [formGroup]="interestForm" class="row g-3 form-group">
                <div class="col-md-8">
                    <mat-form-field class="example-full-width inputboxs" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input type="text" matInput placeholder="Email" formControlName="email">
                    </mat-form-field>
                </div>
                <div class="col-md-8">
                    <mat-form-field class="example-full-width inputboxs" appearance="outline">
                        <mat-label>First Name</mat-label>
                        <input type="text" matInput placeholder="First Name" formControlName="firstName" maxlength="32" (keypress)="charOnly($event)">
                        <div *ngIf="submitted && f.firstName.errors">
                            <mat-error *ngIf="f.firstName.errors?.required">First name name is required.</mat-error>
                            <mat-error *ngIf="f.firstName.errors?.minlength">First name must have atleast 3 characters.</mat-error>
                        </div>
                    </mat-form-field>
                </div>
                <div class="col-md-8">
                    <mat-form-field class="example-full-width inputboxs" appearance="outline">
                        <mat-label>Last Name</mat-label>
                        <input type="text" matInput placeholder="Last Name" formControlName="lastName" maxlength="32" (keypress)="charOnly($event)">
                        <div *ngIf="submitted && f.lastName.errors">
                            <mat-error *ngIf="f.lastName.errors?.required">Last name name is required.</mat-error>
                            <mat-error *ngIf="f.lastName.errors?.minlength">Last name must have atleast 3 characters.</mat-error>
                        </div>
                    </mat-form-field>
                </div>
                <div class="col-md-8">
                    <mat-form-field class="example-full-width inputboxs" appearance="outline">
                        <mat-label>Phone Number</mat-label>
                        <input type="text" matInput placeholder="Phone Number" maxlength="15" (keypress)="numberOnly($event)" formControlName="phoneNumber">
                        <div *ngIf="submitted && f.phoneNumber.errors">
                            <mat-error *ngIf="f.phoneNumber.errors?.required">Phone is required.</mat-error>
                            <mat-error *ngIf="f.phoneNumber.errors?.minlength">Phone Number must have atleast 7 digits.</mat-error>
                        </div>
                    </mat-form-field>
                </div>
            </form>
        </div>

        <div class="modal-btn d-flex justify-content-around mt-3">
            <button class="btn btn-cancel" (click)="cancel()">Cancel</button>
            <button class="btn btn-blue" (click)="edit()">Update User</button>
        </div>
    </div>

</ng-template>

<ng-template #template>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Are you sure you <br>want to logout?</p>
        <div class="modal-btn d-flex justify-content-around">
            <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
            <button class="btn btn-blue" (click)="confirm()">Yes</button>
        </div>
    </div>
</ng-template>
<app-layout></app-layout>
<div class="content">
    <div class="mt-4">
            <div class="total-counts">

                <div class="total-count total-careers mt-3 card-border" routerLink="/service-request/list">
                    <img src="../../../assets/dashboard_icons/Active users.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="totalUsers" class="counter">{{analyticsData?.serviceProviderCount ? analyticsData?.serviceProviderCount : 0}}</h2>
                        <p>Service Providers </p>
                    </div>
                </div>

                <div class="total-count total-users mt-3 card-border" routerLink="/manage/subadmin/list">
                    <img src="../../../assets/dashboard_icons/Devices.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="client" class="counter">{{analyticsData?.subAdminCount ? analyticsData?.subAdminCount : 0}}</h2>
                        <p>Sub Admins </p>
                    </div>
                </div>

                <div class="total-count total-resources mt-3 card-border" routerLink="/buy-leads/list">
                    <img src="../../../assets/dashboard_icons/View Feedback.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="client" class="counter">{{analyticsData?.qbCount ? analyticsData?.qbCount : 0}}</h2>
                        <p>Quick Buy Leads </p>
                    </div>
                </div>

                <div class="total-count total-resources mt-3 card-border" routerLink="/real/estate/leads/list">
                    <img src="../../../assets/dashboard_icons/View Feedback.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="client" class="counter">{{analyticsData?.tpCount ? analyticsData?.tpCount : 0}}</h2>
                        <p>Real Estate Leads </p>
                    </div>
                </div>
            </div>
        </div>


</div>
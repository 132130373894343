import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { HeaderService } from "../services/header.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SharedService } from "../services/shared.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  welcomeUserMsg: boolean = false;
  dashboardHeading:boolean = false;
  changePasswordHeading:boolean = false
  manageCategoriesHeading:boolean = false;
  subAdminHeading:boolean = false;
  privacyPolicyHeading:boolean = false;
  faqsHeading:boolean = false;
  termsconditionsHeading:boolean = false;
  manageSubCategoriesHeading:boolean = false;
  manageServiceHeading:boolean = false;
  buyLeadsHeading: boolean = false;
  tierdPlanHeading: boolean = false;
  serviceRequestHeading: boolean = false;
  serviceProvidersHeading: boolean = false;
  globalQueryHeading: boolean = false;

  @ViewChild("template") template: TemplateRef<any>;
  @ViewChild("template1") template1: TemplateRef<any>;
  modalRef: any;
  interestForm: FormGroup;

  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private authService: AuthService,
    private shared: SharedService,
    private toast: ToastrService
  ) {

    this._header.faqsHeading.subscribe((res) => {
      this.faqsHeading = res;
    });

    this._header.subAdminHeading.subscribe((res) => {
      this.subAdminHeading = res;
    });

    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });

    this._header.dashboardHeading.subscribe((res) => {
      this.dashboardHeading = res;
    });

    this._header.changePasswordHeading.subscribe((res) => {
      this.changePasswordHeading = res;
    });

    this._header.manageCategoriesHeading.subscribe((res) => {
      this.manageCategoriesHeading = res;
    });

    this._header.privacyPolicyHeading.subscribe((res) => {
      this.privacyPolicyHeading = res;
    });

    this._header.termsconditionsHeading.subscribe((res) => {
      this.termsconditionsHeading = res;
    });

    this._header.manageSubCategoriesHeading.subscribe((res) => {
      this.manageSubCategoriesHeading = res;
    });

    this._header.manageServiceHeading.subscribe((res) => {
      this.manageServiceHeading = res;
    });

    this._header.buyLeadsHeading.subscribe((res) => {
      this.buyLeadsHeading = res;
    });

    this._header.tierdPlanHeading.subscribe((res) => {
      this.tierdPlanHeading = res;
    });

    this._header.serviceRequestHeading.subscribe((res) => {
      this.serviceRequestHeading = res;
    });

    this._header.serviceProvidersHeading.subscribe((res) => {
      this.serviceProvidersHeading = res;
    });

    this._header.globalQueryHeading.subscribe((res) => {
      this.globalQueryHeading = res;
    });  
  
  }
  currentUser:any;
  ngOnInit(): void {
    this.initForm();
    this.currentUser = this.authService.currentUserValue;
  }

  initForm(){
    this.interestForm = new FormGroup({
      email: new FormControl({ value: '', disabled: true }),
      firstName: new FormControl("", [Validators.required,Validators.minLength(3)]),
      lastName: new FormControl("", [Validators.required, Validators.minLength(3)]),
      phoneNumber: new FormControl("", [Validators.minLength(7), Validators.required]),
    });
  }

  submitted:boolean = false;
  get f(){
    return this.interestForm.controls;
  }

  logout() {
    this.modalRef.hide();
    this.router.navigateByUrl("/auth/login");
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.modalRef.hide();
    localStorage.clear();
    this.router.navigateByUrl("/auth/login");
  }
  cancel() {
    this.modalRef.hide();
  }

  openLogoutModal(){
    this.openModal(this.template);
  }

  openViewProfileModal(){
    this.getUserDetails();
  }

  getUserDetails(){
    this.shared.getUserDetails(this.currentUser.userId).subscribe((res:any) => {
      this.interestForm.patchValue({
        email: res.data.email,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        phoneNumber: res.data.phoneNumber
      });
      this.openModal(this.template1);
    });
  }

  edit(){
    this.submitted = true;
    this.interestForm.get('firstName').patchValue(this.interestForm.value.firstName?.trim());
    this.interestForm.get('lastName').patchValue(this.interestForm.value.lastName?.trim());
    if(this.interestForm.invalid) return
    this.submitted = false;
    let req: any = {
      "email": this.interestForm.value.email,
      "firstName": this.interestForm.value.firstName,
      "lastName": this.interestForm.value.lastName,
      "phoneNumber": this.interestForm.value.phoneNumber,
    }
    this.shared.updateAdmin(this.currentUser.userId,req).subscribe((res:any)=>{
      if(res.type == "success"){
        this.toast.success(res.message)
        this.modalRef.hide();
      }
    })
  }

  numberOnly(evt: any) {
    let charCode = evt.which ? evt.which : evt.keyCode;
    if(charCode>=48 && charCode<=57){
      return true
    }else {
      return false;
    }
  }

  reset(){
    this.interestForm.reset();
  }

  charOnly(evt: any) {
    let charCode = evt.which ? evt.which : evt.keyCode;
    console.log(charCode)
    if(charCode>=65 && charCode<=90 || charCode>=97 && charCode<=122 || charCode==32){
      return true
    }else {
      return false;
    }
  }
}

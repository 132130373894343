<div class="sidebar" (keydown)="disabledTab($event)">
  <div class="logo"><img src="../../../assets/images/Home_Seller.svg" alt="logo"></div>
  <div class="header-links">

    <a routerLink="/dashboard" routerLinkActive="active" (click)="checkUlr()">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/dashboard-black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/dashboard-white.svg" alt="" height="24" width="24">
    </span>Dashboard</a>

    <a routerLink="/manage/subadmin" routerLinkActive="active" (click)="checkUlr()">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Manage Business.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/Manage_Business_White.svg" alt="" height="24" width="24">
    </span>Manage Sub Admin</a>
    
    <div>
      <button class="dropdown-main grp-card" (click)="onClickDrop()"  
        [ngClass]="{ 'active': isManageReportedContentActive, 'blue-background': currentRoute === '/manage-categories/list' ||  currentRoute === '/manage-sub-categories/list' || currentRoute === '/manage-sub-categories/create' || currentRoute == '/manage-services/list'}">
        <span class="sidebar-icon">
          <img class="black-icon" [src]="currentRoute === '/manage-categories/list' ||  currentRoute === '/manage-sub-categories/list' || currentRoute==='/manage-services/list' || currentRoute === '/manage-sub-categories/create' ? '../../../assets/sidbar_icons/White/Manage_Category_White.svg' : '../../../assets/sidbar_icons/Black/Manage Categories.svg'" alt="" height="28"
            width="28">
        </span> Manage Services, Category <br><span style="margin-left: -25px;">& Sub Category</span><span class="caret"></span>
      </button>

      <div *ngIf="isDropDownVisible">
        <a routerLink="/manage-services/list" routerLinkActive="active" (click)="checkUlr()">
          <img src="../../../assets/icons/arrow-right-solid.svg" class="black-icon" height="16" width="16" alt="">
          Manage Services</a>

        <a routerLink="/manage-categories/list" routerLinkActive="active" (click)="checkUlr()">
          <img src="../../../assets/icons/arrow-right-solid.svg" class="black-icon" height="16" width="16" alt="">
          Manage Category</a>
        <a routerLink="/manage-sub-categories/list" routerLinkActive="active" (click)="checkUlr()">
          <img src="../../../assets/icons/arrow-right-solid.svg" class="black-icon" height="16" width="16" alt="">
          Manage Sub Category</a>
      </div>
    </div>

    <a routerLink="/buy-leads/list" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/buy-leads-black.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/buy-leads-white.svg" alt="" height="24"
          width="24">
      </span>QuickBuy Leads</a>

    <a routerLink="/real/estate/leads/list" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/leads-black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/leads-white.svg" alt="" height="24" width="24">
      </span>Real Estate Leads</a>

    <a routerLink="/service-request/list" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/enquiry-black.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/enquiry-white.svg" alt="" height="24"
          width="24">
      </span>Service Providers Inquiries</a>

      <a routerLink="/global-query/list" routerLinkActive="active">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/sidbar_icons/Black/enquiry-black.svg" alt="" height="24"
            width="24">
          <img class="white-icon" src="../../../assets/sidbar_icons/White/enquiry-white.svg" alt="" height="24"
            width="24">
        </span>Contact Us</a>

    <a routerLink="/privacy-policy" routerLinkActive="active" (click)="checkUlr()">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Black privacy policy.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/White privacy policy.svg" alt="" height="24" width="24">
    </span>Privacy Policy</a>

    <a routerLink="/terms-conditions" routerLinkActive="active" (click)="checkUlr()">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Manage Business.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/Manage_Business_White.svg" alt="" height="24" width="24">
    </span>Terms & Conditions</a>

    <a routerLink="/faqs" routerLinkActive="active" (click)="checkUlr()">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/faq.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/faq.svg" alt="" height="24" width="24">
    </span>FAQ</a>
    
  </div>
</div>

<div id="forgot-password">
    <div class="container-fluid">
        <div class="row">
            <div class="logo"><img src="../../../../assets/images/Home_Seller.svg" routerLink="/" alt="logo"></div>
            <div class="col-md-12">
                <div class="login-field">
                    <div class="login-box new-box-property"
                        [ngClass]="{'error-available': submitted && f.password.errors, 'no-error': submitted && !f.password.errors}">
                        <h3>Create New Password</h3>
                        <form [formGroup]="resetForm" (submit)="resetPassword()">
                            <div class="form-group">
                                <label for="password">New Password<span class="require">*</span></label>
                                <span class="input-icon"><img src="../../../../assets/icons/password.svg" alt=""
                                        style="margin-top: -10px;"></span>
                                <input [type]="hide ? 'password' : 'text'" class="form-control"
                                    placeholder="New Password" id="password" formControlName="password">
                                <mat-icon class="placeholder" (click)="myFunction()">{{hide ? 'visibility_off' :
                                    'visibility'}}</mat-icon>
                                <div *ngIf="f.password.touched && f.password.errors" class="alert alert-danger">
                                        <span *ngIf="f.password.errors.required">
                                            Password is required.
                                        </span>
                                        <span *ngIf="f.password.errors.minlength">
                                            Password must have at least 8 characters.
                                        </span>
                                        <span *ngIf="f.password.errors.pattern">
                                            Password must contain at least one Uppercase, Lowercase, Digit and One Special symbol
                                        </span>
                                </div>
                            </div>
                            <span class="back-signin" routerLink="/">Back to Sign In</span>
                            <button type="submit" class="btn btn-primary">Change
                                Password</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
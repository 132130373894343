import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  welcomeUserMsg = new BehaviorSubject(false);
  dashboardHeading =  new BehaviorSubject(false);
  changePasswordHeading = new BehaviorSubject(false);
  manageCategoriesHeading = new BehaviorSubject(false);
  manageSubCategoriesHeading = new BehaviorSubject(false);
  faqsHeading= new BehaviorSubject(false);
  subAdminHeading= new BehaviorSubject(false);
  privacyPolicyHeading= new BehaviorSubject(false);
  termsconditionsHeading= new BehaviorSubject(false);
  manageServiceHeading= new BehaviorSubject(false);
  buyLeadsHeading= new BehaviorSubject(false);
  tierdPlanHeading= new BehaviorSubject(false);
  serviceRequestHeading= new BehaviorSubject(false);
  serviceProvidersHeading= new BehaviorSubject(false);
  globalQueryHeading= new BehaviorSubject(false);
}

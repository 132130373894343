import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

      {
        path: "dashboard",
        loadChildren: () =>
        import("../app/dashboard/dashboard.module").then(
          (m) => m.DashboardModule
        )
      },

      {
        path: "manage-categories",
        loadChildren: () =>
        import("../app/views/manage-category/manage-category.module").then(
          (m) => m.ManageCategoryModule
        )
      },

      {
        path: "manage-sub-categories",
        loadChildren: () =>
        import("../app/views/manage-sub-category/manage-sub-category.module").then(
          (m) => m.ManageSubCategoryModule
        )
      },

      {
        path: "privacy-policy",
        loadChildren: () =>
        import("../app/views/privacy-policy/privacy-policy.module").then(
          (m) => m.PrivacyPolicyModule
        )
      },

      {
        path: "terms-conditions",
        loadChildren: () =>
        import("../app/views/term-conditions/term-conditions.module").then(
          (m) => m.TermConditionsModule
        )
      },

      {
        path: "faqs",
        loadChildren: () =>
        import("../app/views/faq/faq.module").then(
          (m) => m.FaqModule
        )
      },

      {
        path: "manage/subadmin",
        loadChildren: () =>
        import("../app/views/manage-sub-admin/manage-admin.module").then(
          (m) => m.ManageAdminsModule
        )
      },

      {
        path: "manage-services",
        loadChildren: () =>
        import("../app/views/manage-service/manage-service.module").then(
          (m) => m.ManageServiceModule
        )
      },
      {
        path: "buy-leads",
        loadChildren: () =>
        import("../app/views/buy-leads/buy-leads.module").then(
          (m) => m.BuyLeadsModule
        )
      },

      {
        path: "real/estate/leads",
        loadChildren: () =>
        import("../app/views/tired-plan/tired-plan.module").then(
          (m) => m.TiredPlanModule
        )
      },

      {
        path: "service-request",
        loadChildren: () =>
        import("../app/views/service-request/service-request.module").then(
          (m) => m.ServiceRequestModule
        )
      },
      {
        path: "global-query",
        loadChildren: () =>
        import("../app/views/global-query/global-query.module").then(
          (m) => m.GlobalQueryModule
        )
      },

    ],
  },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

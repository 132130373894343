import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TermConditionsService {

  url: any = environment.API_URL;


  constructor(
    private http: HttpClient
  ) { }
  
  getTermsConditionList() {
    return this.http.get<any>(this.url + 'terms-condition/list');
  }

  addTermsCondition(data: any) {
    return this.http.post(this.url + 'terms-condition/add', data);
  }

  updateTermsCondition(id:any,data: any) {
    return this.http.put(this.url + `terms-condition/update/${id}`, data);
  }

  deleteTermsCondition(id: any) {
    return this.http.delete(this.url + `terms-condition/delete/${id}`);
  }
}

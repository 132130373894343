import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(
    private authService: AuthService,
    public router: Router,
  ) {
    this.isDropDownVisible = false
    this.isDropDownVisible1 = false
    this.isManageReportedContentActive = false
    this.currentRoute = this.router.url;
    if(this.currentRoute?.includes('?')){
      this.currentRoute = this.currentRoute.split('?')[0]
    }
    this.isManageReportedContentActive1 = false
  }
  isActive1(url: string): boolean {
    return this.router.url === url || this.router.url.startsWith(url);

  }

  modalRef: any;
  currentUser:any;
  isReportedUsersActive: boolean = false;

  
  isMenuOpen:false
  isDropDownVisible: boolean;
  isDropDownVisible1: boolean;
  isManageReportedContentActive: boolean

  currentRoute: string;
  isManageReportedContentActive1: boolean

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.checkUlr();
  }

  ngDoCheck(){
  
    if(window.location.pathname.indexOf('coupon') != -1){
      document.getElementById('couponmenu').scrollIntoView();
    }else if(window.location.pathname.indexOf('coordinates') != -1){
      document.getElementById('Coordinates').scrollIntoView();
    }else if(window.location.pathname.indexOf('event-sponsors') != -1){
      document.getElementById('Sponsors').scrollIntoView();
    }else if(window.location.pathname.indexOf('treasure') != -1){
      document.getElementById('Treasure').scrollIntoView();
    }else if(window.location.pathname.indexOf('more-sections') != -1){
      document.getElementById('More-Section').scrollIntoView();
    }else if(window.location.pathname.indexOf('home-partners') != -1){
      document.getElementById('home-partners').scrollIntoView();
    }
  

  }

  disabledTab(objEvent) {
    console.log(objEvent.keyCode+"sidbar");
  if (objEvent.keyCode == 9) {
      objEvent.preventDefault();
  }
}

onClickDrop(){
  this.isDropDownVisible =true;
}

checkUlr(){
  let x  = setInterval(() => {
    let currentRoute = this.router.url;
    if(currentRoute === '/manage-categories/list' ||  currentRoute === '/manage-sub-categories/list' || currentRoute === '/manage-sub-categories/create' || currentRoute == '/manage-services/list'){
      this.isDropDownVisible = true;
      clearInterval(x);
    }
  }, 10);
}

  
}

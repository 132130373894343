import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public canvas: any;
  public ctx: any;
  public chart: any;
  dashboardData: any = [];
  dashboardAllData:any;
  graphData: any;
  months: number = 6;
  currentUser:any;
  analyticsData:any;
  finalCount:any;
  clientCounts:number=0;
  careGiverCounts:number=0;
  bookingCount:number=0;
  incomeCount:number=0;
  languages:any;

  constructor(
    private _header: HeaderService, 
    private dashboardService: DashboardService,
    private authService: AuthService
    ) { }

  ngOnInit(): void {
    this.languages = localStorage.getItem('language');
    this._header.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;

    this.dashboardService.getAnalytics().subscribe((res:any) => {
      if(res.type == "success"){
         this.analyticsData = res.data;
      }
    })
  }
  

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ServiceRequestService {

  url: any = environment.API_URL;


  constructor(
    private http: HttpClient
  ) { }
  
  getRequestList(data: any) {
    return this.http.get<any>(this.url + `user/service-provider-inquiry-list?page=${data?.page}&limit=${data?.limit}`);
  }

  deleteRequest(id: any) {
    return this.http.delete(this.url + 'user/delete-inquiry/'+id);
  }

  updateServiceProvider(id: any, data:any) {
    return this.http.put(this.url + `user/update-sp-inquiry/${id}`, data);
  }
}

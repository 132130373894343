import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PrivacyPolicyService {

  url: any = environment.API_URL;


  constructor(
    private http: HttpClient
  ) { }
  
  getPrivacyList() {
    return this.http.get<any>(this.url + "privacy-policy/list");
  }

  addPrivacy(data: any) {
    return this.http.post(this.url + 'privacy-policy/add', data);
  }

  updatePrivacy(id:any,data: any) {
    return this.http.put(this.url + `privacy-policy/update/${id}`, data);
  }

  deletePrivacy(id:any) {
    return this.http.delete(this.url + `privacy-policy/delete/${id}`);
  }
}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ManageSubCategoryService {

  url: any = environment.API_URL;


  constructor(
    private http: HttpClient
  ) { }

  getCompanyList(data: any) {
    return this.http.post(this.url + `sub-category/list`, data);
  }

  getCompanyDataById(id: any) {
    return this.http.get(this.url + 'sub-category/details/'+id);
  }

  subCategoryAdd(data: any) {
    return this.http.post(this.url + 'sub-category/add', data);
  }

  updateSubCategory(id:any,data: any) {
    return this.http.put(this.url + `sub-category/update/${id}`, data);
  }

  deleteSubCategory(id: any) {
    return this.http.delete(this.url + `sub-category/delete/${id}`);
  }

  //old
  getEvents(data: any) {
    return this.http.post(this.url + 'event/list', data);
  }

  deleteEvent(data: any) {
    const httpOptions = {
        headers: new HttpHeaders({}), body: data
    };
    console.log('data', data);
    return this.http.delete(this.url + 'event/delete', httpOptions);
  }
}
